<!--
 * @Description: 实名认证弹出抽屉
 * @Date: 2020-01-11 09:36:57
 * @LastEditTime: 2020-06-13 14:00:41
 * @LastEditors: 肖槿
 -->
<template>
  <div class="realname-approval-detail">
    <a-card title="身份证">
      <a-row>
        <a-col class="info" :span='8'>真实姓名：{{detailInfo.name}}</a-col>
        <a-col class="info" :span='8'>性别：{{genderName}}</a-col>
        <a-col class="info" :span='8'>年龄：{{detailInfo.age}}</a-col>
        <a-col class="info" :span='24'>身份证：{{detailInfo.identity}}</a-col>
        <a-col class="info" :span='12'>签发日期：{{detailInfo.id_start_time}}</a-col>
        <a-col class="info" :span='12'>失效日期：{{detailInfo.id_end_time}}</a-col>
        <a-col class="info" :span='24'>身份证照片：</a-col>
      </a-row>
      <a-row :gutter="16">
        <a-col class="info" :span='12'>
          <img id="id-card-1" class="id-card" :src="detailInfo.photo" />
        </a-col>
        <a-col class="info" :span='12'>
          <img id="id-card-2" class="id-card" :src="detailInfo.back_photo" />
        </a-col>
      </a-row>
    </a-card>
    <br>
    <a-card title="银行卡">
      <a-row>
        <a-col class="info" :span='24'>所属银行：{{detailInfo.bank}}</a-col>
        <a-col class="info" :span='24'>银行卡号：{{detailInfo.bank_card_num}}</a-col>
        <a-col class="info" :span='24'>开户行：{{detailInfo.opening_bank}}</a-col>
      </a-row>
      <a-row :gutter="16">
        <a-col class="info" :span='12'>
          <img id="bank-card" class="id-card" :src="detailInfo.bank_photo" />
        </a-col>
      </a-row>
    </a-card>
    <br>
    <a-card title="记录">
      <a-list>
        <a-list-item>
          <a-list-item-meta>
            <div slot="title">1. {{detailInfo.user__name}}提交资料 {{detailInfo.create_time}}</div>
          </a-list-item-meta>
        </a-list-item>
        <a-list-item v-if="detailInfo.audit_user__name">
          <a-list-item-meta :description="detailInfo.fail_reasons">
            <div slot="title">2. {{detailInfo.audit_user__name}}{{getStatusText(detailInfo.status)}} {{detailInfo.audit_time}}</div>
          </a-list-item-meta>
        </a-list-item>
      </a-list>
      <template v-if="enableEdit">
        <a-divider />
      <a-form v-if="isApproval" :form="form" @submit="handleAuditFormSubmit">
        <a-form-item>
          <a-radio-group v-decorator="[ 'status', { rules: [{ required: true, message: '请选择审核结果' }] } ]" @change="handleRadioChange">
            <a-radio :value="3">通过</a-radio>
            <a-radio :value="2">拒绝</a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item v-if="visiableReason">
          <a-textarea v-decorator="[ 'fail_reasons', { rules: [{ required: true, message: '请填写拒绝原因' }] } ]" placeholder="请填写拒绝原因" :autosize="{ minRows: 2, maxRows: 6 }" />
        </a-form-item>
        <a-form-item>
          <a-button style="margin-right:10px;" @click="handleCancelClick">取消</a-button>
          <a-button type="primary" html-type="submit">提交</a-button>
        </a-form-item>
      </a-form>
      </template>
    </a-card>
    <a-modal
      title="实名资料审核"
      :width="320"
      v-model="modalVisiable"
      wrapClassName="realname-approval-modal"
      centered
      :okText="getApprovalStatus() === REAL_NAME_APPROVAL_STATUS.APPROVAL_SUCCESS.VALUE ? '确认通过' : '确认驳回'"
      @ok="handleModalOkClick"
    >
      <div class="approval-modal-content">
        <p v-show="getApprovalStatus() === REAL_NAME_APPROVAL_STATUS.APPROVAL_SUCCESS.VALUE">确认通过{{detailInfo.name}}的实名认证资料？</p>
        <p v-show="getApprovalStatus() === REAL_NAME_APPROVAL_STATUS.APPROVAL_FAILED.VALUE">确认驳回{{detailInfo.name}}的实名认证资料？</p>
      </div>
    </a-modal>
  </div>
</template>

<script>
import 'viewerjs/dist/viewer.css'
import Viewer from 'viewerjs'
import { GENDER_CODE, REAL_NAME_APPROVAL_STATUS } from '../../const/index'
import { realnameApproval } from '../../service/user'
export default {
  name: 'RealnameApprovalDetail',
  props: [ 'detail', 'enableEdit' ],
  data () {
    return {
      REAL_NAME_APPROVAL_STATUS,
      form: this.$form.createForm(this),
      detailInfo: {},
      visiableReason: false,
      modalVisiable: false,
      isApproval: false
    }
  },
  computed: {
    // 性别
    genderName () {
      if (this.detailInfo.gender) {
        if (this.detailInfo.gender === GENDER_CODE.MALE.VALUE) return GENDER_CODE.MALE.TEXT
        if (this.detailInfo.gender === GENDER_CODE.FEMALE.VALUE) return GENDER_CODE.FEMALE.TEXT
      }
      return ''
    }
  },
  methods: {
    // 初始化
    init (type) {
      this.isApproval = type !== 'show'
      this.detailInfo = this.$props.detail
      // 初始化图片查看器
      const viewer1 = new Viewer(document.getElementById('id-card-1'), { navbar: false, toolbar: false, hidden () { viewer1.destroy() } })
      document.getElementById('id-card-1').onclick = () => viewer1.show()
      const viewer2 = new Viewer(document.getElementById('id-card-2'), { navbar: false, toolbar: false, hidden () { viewer2.destroy() } })
      document.getElementById('id-card-2').onclick = () => viewer2.show()
      const viewer3 = new Viewer(document.getElementById('bank-card'), { navbar: false, toolbar: false, hidden () { viewer3.destroy() } })
      document.getElementById('bank-card').onclick = () => viewer3.show()
    },
    // 审核结果切换
    handleRadioChange (e) {
      this.visiableReason = e.target.value === REAL_NAME_APPROVAL_STATUS.APPROVAL_FAILED.VALUE
    },
    // 获取审核状态
    getApprovalStatus () {
      return this.form.getFieldValue('status')
    },
    // 表单提交
    handleAuditFormSubmit (e) {
      e.preventDefault()
      this.form.validateFields(err => {
        if (!err) {
          this.modalVisiable = true
        }
      })
    },
    // 点击取消按钮
    handleCancelClick () {
      if (this.isApproval) this.$emit('close')
    },
    // 审核弹窗点击确认
    handleModalOkClick () {
      this.realnameApproval()
    },
    // 发起审核请求
    async realnameApproval () {
      const { err, res } = await realnameApproval(this.detailInfo.id, this.form.getFieldsValue())
      if (!err) {
        if (res.success) {
          this.$message.success(res.message)
          this.visiableReason = false
          this.modalVisiable = false
          this.form.setFields()
          if (this.isApproval) this.$emit('close')
        } else {
          this.$message.error(res.message)
        }
      }
    },
    // 获取状态文字
    getStatusText (status) {
      if (status === REAL_NAME_APPROVAL_STATUS.APPROVAL_FAILED.VALUE) return '驳回资料'
      if (status === REAL_NAME_APPROVAL_STATUS.APPROVAL_SUCCESS.VALUE) return '审核通过'
    }
  }
}
</script>

<style lang='less' scoped>
.realname-approval-detail {
  .info {
    line-height: 30px;
    .id-card {
      width: 268px;
      height: 196px;
      cursor: pointer;
    }
  }
  .ant-list-item-meta-description {
    background: #F0F0F0;
    padding: 8px 10px;
    border-radius: 5px;
  }
}
</style>
<style lang="less">
.realname-approval-modal {
  .ant-modal-header {
    border-bottom: none;
  }
  .ant-modal-title {
    text-align: center;
  }
  .approval-modal-content {
    text-align: center;
  }
  .ant-modal-footer {
    text-align: center;
    border-top: none;
  }
}
</style>