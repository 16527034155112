<!--
 * @Description: 实名认证
 * @Date: 2020-01-10 14:42:12
 * @LastEditTime: 2020-04-14 09:40:03
 * @LastEditors: 前胡
 -->
<template>
  <div id="realname-approval">
    <a-tabs type="card" @change="tabChangeCallback">
      <a-tab-pane tab="申请列表" key="1"></a-tab-pane>
      <a-tab-pane tab="审核记录" key="2"></a-tab-pane>
    </a-tabs>
    <div class="tab-content">
      <a-form
        class="filter-condition-form"
        layout="inline"
        :form="form"
        @submit="handleFormSubmit"
        v-auth="'cash_manage_identity_list'"
      >
        <a-form-item>
          <a-input
            v-decorator="['search']"
            placeholder="注册手机/姓名"
            allowClear
            @change="handleSearchInputChange"
          />
        </a-form-item>
        <a-form-item label="审核状态" v-if="currentTab === '2'">
          <a-select
            v-decorator="['status', { initialValue: 'all' }]"
            defaultValue="all"
            style="width: 120px"
          >
            <a-select-option value="all">全部</a-select-option>
            <a-select-option :value="REAL_NAME_APPROVAL_STATUS.APPROVAL_SUCCESS.VALUE">通过</a-select-option>
            <a-select-option :value="REAL_NAME_APPROVAL_STATUS.APPROVAL_FAILED.VALUE">驳回</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-button type="primary" html-type="submit">查询</a-button>
        </a-form-item>
      </a-form>
      <a-table
        :scroll="{ y: tableScrollY }"
        v-auth="'cash_manage_identity_list'"
        :rowKey="record => record.id"
        :columns="columns"
        :dataSource="list"
        :pagination="pagination"
        :locale="{ emptyText: '暂无申请用户' }"
        :loading="loading"
        :customRow="handleRecordClick"
        @change="handleTableChange"
      >
        <template slot="operation" slot-scope="record">
          <a-button
          class="mr-10"
            type="primary"
            size="small"
            @click="handleAudioClick(record)"
            v-auth="'cash_manage_audit_identity'"
          >审核</a-button>
          <a-button
            size="small"
            @click="handleShowClick(record)"
          >查看</a-button>
        </template>
        <template slot="status" slot-scope="status">
          <span
            :class="{ 'passed': REAL_NAME_APPROVAL_STATUS.APPROVAL_SUCCESS.VALUE == status, 'not-pass': REAL_NAME_APPROVAL_STATUS.APPROVAL_FAILED.VALUE == status }"
          >{{getStatusText(status)}}</span>
        </template>
      </a-table>
      <a-drawer
        title="实名认证审核"
        placement="right"
        width="850"
        :visible="realnameDrawerVisiable"
        @close="handleRealnameDrawerClose"
      >
        <RealnameApprovalDetail
            v-if="realnameDrawerVisiable"
          ref="realnameApproval"
          :enableEdit="isRealnameApprovalEdit"
          :detail="drawerDetail"
          @close="handleRealnameDrawerClose"
        />
      </a-drawer>
    </div>
  </div>
</template>

<script>
const listColumns = [
  {
    title: "注册手机",
    dataIndex: "user__mobile",
    align: "center",
    width: 200
  },
  {
    title: "姓名",
    dataIndex: "name",
    align: "center"
  },
  {
    title: "申请时间",
    dataIndex: "create_time",
    width: "25%",
    align: "center"
  },
  {
    title: "操作",
    align: "center",
    width: 180,
    scopedSlots: { customRender: "operation" }
  }
];

const recordColumns = [
  {
    title: "注册手机",
    dataIndex: "user__mobile",
    align: "center",
    width: 200
  },
  {
    title: "姓名",
    dataIndex: "user__name",
    align: "center"
  },
  {
    title: "审核状态",
    dataIndex: "status",
    scopedSlots: { customRender: "status" },
    align: "center"
  },
  {
    title: "审核人",
    dataIndex: "audit_user__username",
    align: "center"
  },
  {
    title: "审核时间",
    dataIndex: "audit_time",
    align: "center"
  }
];

import RealnameApprovalDetail from "../../../components/admin/realname-approval-detail";

import { getRealnameList } from "../../../service/user";
import { REAL_NAME_APPROVAL_STATUS } from "../../../const/index";
import { checkPer } from "@/utils/index";

export default {
  name: "RealnameApproval",
  components: { RealnameApprovalDetail },
  data() {
    return {
      tableScrollY: 600,
      REAL_NAME_APPROVAL_STATUS,
      isRealnameApprovalEdit: true,
      form: this.$form.createForm(this),
      currentTab: "1",
      columns: listColumns,
      pagination: {
        current: 1,
        pageSize: 10,
        pageSizeOptions: ["10", "20", "30", "40", "50"],
        showTotal: total =>
          `共 ${total} 条记录 第 ${this.pagination.current}/${Math.ceil(
            total / this.pagination.pageSize
          )} 页`,
        showQuickJumper: true,
        showSizeChanger: true,
        onShowSizeChange: (current, pageSize) =>
          (this.pagination.pageSize = pageSize)
      },
      list: [],
      loading: false,
      realnameDrawerVisiable: false,
      drawerDetail: null
    };
  },
  created() {
    var windowHeight = document.documentElement.clientHeight || 1000;
    this.tableScrollY = windowHeight - 345;
  },
  mounted() {
    // 初始第一次获取
    this.getRealnameList();
  },
  methods: {
    // 切换tab
    tabChangeCallback(key) {
      this.currentTab = key;
      this.pagination.current = 1;
      this.form.resetFields();
      if (key === "1") {
        this.columns = listColumns;
        this.getRealnameList();
      }
      if (key === "2") {
        this.columns = recordColumns;
        this.getRealnameList("record");
      }
    },
    // 提交表单
    handleFormSubmit(e) {
      e.preventDefault();
      this.pagination.current = 1;
      if (this.currentTab === "1") this.getRealnameList();
      if (this.currentTab === "2") this.getRealnameList("record");
    },
    // 审核
    handleAudioClick(record) {
      this.drawerDetail = record;
      this.isRealnameApprovalEdit = true,
      this.realnameDrawerVisiable = true;
      this.$nextTick(() => {
        this.$refs.realnameApproval.init();
      });
    },
    // 查看
    handleShowClick(record) {
      this.drawerDetail = record;
      this.isRealnameApprovalEdit = false,
      this.realnameDrawerVisiable = true;
      this.$nextTick(() => {
        this.$refs.realnameApproval.init();
      });
    },
    // 获取实名认证用户列表 type list record
    async getRealnameList(type = "list") {
      if (!checkPer("cash_manage_identity_list")) {
        this.$message.error("无权限获取列表");
        return false;
      }
      this.loading = true;
      const data = this.form.getFieldsValue();
      if (data.status === "all") data.status = undefined;
      data.page = this.pagination.current;
      data.page_size = this.pagination.pageSize;
      // const data = { page: this.pagination.current, search: this.form.getFieldValue('search'), status: this.form.getFieldValue('status') }
      if (type === "list")
        data.status = REAL_NAME_APPROVAL_STATUS.APPROVING.VALUE;
      if (type === "record") {
        data.status__gte = 2;
        data.ordering = "-audit_time";
      }
      const { err, res } = await getRealnameList(data);

      if (!err) {
        if (res.success) {
          const pagination = { ...this.pagination };
          this.list = res.data.results;
          pagination.total = res.data.count;
          this.pagination = pagination;
        } else {
          this.$message.warning(res.message);
        }
      }

      this.loading = false;
    },
    // 表格翻页
    handleTableChange(pagination) {
      if (pagination) this.pagination.current = pagination.current;
      if (this.currentTab === "1") {
        this.getRealnameList();
      } else if (this.currentTab === "2") {
        this.getRealnameList("record");
      }
    },
    // 实名认证审核弹窗关闭回调
    handleRealnameDrawerClose() {
      this.realnameDrawerVisiable = false;
      if (this.currentTab === "1") this.getRealnameList();
    },
    // 获取状态名称
    getStatusText(status) {
      let statusText = "";
      for (let key in REAL_NAME_APPROVAL_STATUS) {
        if (status === REAL_NAME_APPROVAL_STATUS[key].VALUE) {
          statusText = REAL_NAME_APPROVAL_STATUS[key].TEXT;
        }
      }
      return statusText;
    },
    // 当搜索输入框数据被清空
    handleSearchInputChange(e) {
      // debugger
      if (e.target.value !== "") return false;
      this.pagination.current = 1;
      if (this.currentTab === "1") {
        this.getRealnameList();
      } else if (this.currentTab === "2") {
        this.getRealnameList("record");
      }
    },
    // 单条数据点击事件
    handleRecordClick(record) {
      return {
        on: {
          click: () => {
            if (this.currentTab === "1") return;
            this.drawerDetail = record;
            this.realnameDrawerVisiable = true;
            this.$nextTick(() => {
              this.$refs.realnameApproval.init("show");
            });
          }
        }
      };
    }
  }
};
</script>

<style lang='less' scoped>
@import "../../../assets/less/var.less";
#realname-approval {
  .passed {
    color: @green-color;
  }
  .not-pass {
    color: @red-color;
  }
}
.mr-10 {margin-right: 10px;}
</style>
